/** INITIALISE SCROLL FADE **/
AOS.init({disable: 'mobile'});

/** SCROLL TO OPEN TAB **/
$('.nav button').click(function(e) {
    e.preventDefault()
    $(this).tab('show')
  })

  $('button.nav-link').on('click', function(e) {
    var href = $(this).attr('href');
    setTimeout(() => {
        $('html, body').animate({
            scrollTop: $('.tab-pane.show').offset().top -1
        }, 'fast');
    }, 500);
    e.preventDefault();
  });


  /** SVG ANIMATION **/

  var animation = bodymovin.loadAnimation({
    container: document.getElementById('bm'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: 'data.json',
  })

  var animation = bodymovin.loadAnimation({
    container: document.getElementById('uk'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: 'uk-data.json',
  })

  var animation = bodymovin.loadAnimation({
    container: document.getElementById('world'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: 'world-data.json',
  })
